import "./TabletUsers.css"
import axios from "axios";
import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import {useLocation} from 'react-router-dom';
import Button from "@mui/material/Button";
import PersianDateUtil from "../../lib/PersianDateUtil";
import {MDBIcon} from "mdbreact";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TabletUserRow from "./TabletUserRow";
import {MenuItem, Select} from "@mui/material";
import TextField from "@mui/material/TextField";

export default function TabletUsers(props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tabletId = queryParams.get('tabletId');
    const androidId = queryParams.get('aid');
    const identifier = queryParams.get('ide');
    const [searchQuery, setSearchQuery] = useState('');

    const [tabletUserList, setTabletUserList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [firstPage, setFirstPage] = useState(true);
    const [lastPage, setLastPage] = useState(false);
    const [selectedTabletUserId, setSelectedTabletUserId] = useState(null);
    const [unarchiveId, setUnarchiveId] = useState(null);
    const [displayArchive, setDisplayArchive] = useState(false);
    const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
    const [openUnarchiveDialog, setOpenUnarchiveDialog] = useState(false);
    const [openRecoveryPhraseDialog, setOpenRecoveryPhraseDialog] = useState(false);
    const [recoveryPhrase, setRecoveryPhrase] = useState('');

    // const todayFa =
    function handleSearchChange(event) {
        setSearchQuery(event.target.value);
    }

    function handleRecoveryPhraseDialogClose(event) {
        setOpenRecoveryPhraseDialog(false);
        setRecoveryPhrase('');
    }

    function handleRecoveryPhraseDialogSave(event) {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/api/tablet-users/set-recovery-phrase/${selectedTabletUserId}?recoveryPhrase=${recoveryPhrase}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`,
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            setSelectedTabletUserId(null);
            setOpenRecoveryPhraseDialog(false);
            getTabletUsers(tabletId, displayArchive);
        });
        setRecoveryPhrase('');
    }

    function handleSearchSubmit(event) {
        event.preventDefault();
        setCurrentPage(1);
    }

    useEffect(() => {
        setDisplayArchive("");
        getTabletUsers(tabletId, displayArchive);
    }, [currentPage, searchQuery]); // Fetch the data when the component mounts

    ///////////////
    function handlePageChange(pageNumber) {
        setCurrentPage(pageNumber);
    }

    const pageItems = [];

    // Add the first page item
    pageItems.push(
        <Pagination.Item key={1} active={currentPage === 1} onClick={() => handlePageChange(1)}>
            {1}
        </Pagination.Item>
    );

    // Add the previous ellipsis if necessary
    if (currentPage > 3 && totalPages > 5) {
        pageItems.push(<Pagination.Ellipsis key="prev-ellipsis"/>);
    }

    // Add the pages between the first and last pages
    for (let page = Math.max(2, currentPage - 2); page <= Math.min(totalPages - 1, currentPage + 2); page++) {
        pageItems.push(
            <Pagination.Item key={page} active={currentPage === page} onClick={() => handlePageChange(page)}>
                {page}
            </Pagination.Item>
        );
    }

    // Add the next ellipsis if necessary
    if (totalPages - currentPage > 3 && totalPages > 5) {
        pageItems.push(<Pagination.Ellipsis key="next-ellipsis"/>);
    }

    // Add the last page item
    pageItems.push(
        <Pagination.Item key={totalPages} active={currentPage === totalPages}
                         onClick={() => handlePageChange(totalPages)}>
            {totalPages}
        </Pagination.Item>
    );

    /////////////

    function getTabletUsers(tabletId, shouldDisplayArchive) {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/tablet-users-panel?page=${currentPage - 1}&size=${pageSize}&archived.equals=${shouldDisplayArchive}&sort=id,desc&searchField.contains=${encodeURIComponent(searchQuery)}&${tabletId ? `&tabletId.equals=${tabletId}` : ''}`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        })
            .then((res) => {
                setTotalPages(res.data.totalPages);
                setTabletUserList(res.data.content);
                setFirstPage(res.data.first);
                setLastPage(res.data.last);
            }).catch((err) => console.log(err));
    }

    const handleArchiveConfirm = async () => {
        try {
            axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_URL}/api/tablet-user-archive/${selectedTabletUserId}`,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`,
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                setTabletUserList(prevData => prevData.filter(row => row.id !== selectedTabletUserId));
                setSelectedTabletUserId(null);
                setOpenArchiveDialog(false);
            });
        } catch (error) {
            console.error('Error unarchiving record:', error);
            // Handle error
        }
    };

    const handleUnarchiveConfirm = async () => {
        try {
            // Send DELETE request to the API endpoint
            axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_URL}/api/tablet-user-restore/${unarchiveId}`,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`,
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                setTabletUserList(prevData => prevData.filter(row => row.id !== unarchiveId));
                setUnarchiveId(null);
                setOpenUnarchiveDialog(false);
            });
        } catch (error) {
            console.error('Error deleting record:', error);
            // Handle error
        }
    };

    const handleArchiveDeleteCancel = () => {
        setSelectedTabletUserId(null);
        setOpenArchiveDialog(false);
    };

    const handleUnarchiveDeleteCancel = () => {
        setUnarchiveId(null);
        setOpenUnarchiveDialog(false);
    };

    const handleArchivedSpecifiedChange = (event) => {
        let value = event.target.value;
        if (value === "0") value = "false";
        else if (value === "1") value = "true";
        setDisplayArchive(event.target.value);
        getTabletUsers(tabletId, value);
    }

    return (<>
        <div className="material-box material-box-green">
            <h1>کاربران تبلت</h1>
        </div>
        <div className="input-group md-form form-sm form-1 pl-0" style={{paddingBottom: 10}}>

                        <span className="input-group-text purple lighten-3" id="basic-text1">
                                 <MDBIcon className="text-white" icon="search" onClick={handleSearchSubmit}/>
                        </span>
            <input
                className="form-control my-0 py-1"
                type="text"
                placeholder="جست و جو در مدل، شناسه داخلی و Android ID"
                aria-label="Search"
                value={searchQuery}
                onChange={handleSearchChange}
                onSubmit={handleSearchSubmit}
            />
        </div>
        {androidId && <div className="material-box">
            <span>تبلت: <a
                href={`/tablet-users?tabletId=${tabletId}&aid=${androidId}&ide=${identifier ?? ''}`}>{androidId}</a></span>
            <br/>
            {
                identifier !== ''
                    ? <span>شناسه داخلی تبلت: <a href={`/tablet-users?tabletId=${tabletId}`}>{identifier}</a></span>
                    : <span>شناسه داخلی تبلت: تخصیص داده نشده!</span>
            }
        </div>}
        <Select
            style={{marginLeft: 10, paddingTop: 10, maxWidth: 400}}
            value={displayArchive}
            onChange={handleArchivedSpecifiedChange}
            displayEmpty
        >
            <MenuItem disabled value="">
                <em>فیلتر آرشیو بودن کاربران</em>
            </MenuItem>
            <MenuItem value="0">آرشیو نشده‌ها</MenuItem>
            <MenuItem value="1">آرشیو شده‌ها</MenuItem>
        </Select>
        <Table id="tablecontent" className="material-table" striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>نام</th>
                <th>نام خانوادگی</th>
                <th>تبلت</th>
                <th>شناسه داخلی</th>
                <th>تاریخ ایجاد</th>
                <th>زمان ایجاد</th>
                <th>عبارت بازیابی</th>
                <th>عملیات</th>
            </tr>
            </thead>
            <tbody>
            {
                tabletUserList.map((row, idx) => {
                    return (
                        <TabletUserRow
                            key={idx}
                            row={row}
                            persianDate={PersianDateUtil.getPersianDate(row.createTimeStamp)}
                            handleArchiveClick={() => {
                                setSelectedTabletUserId(row.id);
                                setOpenArchiveDialog(true);
                            }}
                            handleUnArchiveClick={() => {
                                setUnarchiveId(row.id);
                                setOpenUnarchiveDialog(true);
                            }}
                            handleAssignRecoveryPhraseClick={() => {
                                setSelectedTabletUserId(row.id);
                                setOpenRecoveryPhraseDialog(true);
                            }}
                        />
                    );
                })
            }
            </tbody>
            <Dialog open={openArchiveDialog} onClose={handleArchiveDeleteCancel}>
                <DialogTitle>تایید حذف</DialogTitle>
                <DialogContent>
                    آیا اطمینان دارید از حذف این مورد؟
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleArchiveDeleteCancel} color="primary">
                        خیر
                    </Button>
                    <Button onClick={handleArchiveConfirm} color="secondary">
                        پاک شود
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openUnarchiveDialog} onClose={handleUnarchiveDeleteCancel}>
                <DialogTitle>تایید بازگشت</DialogTitle>
                <DialogContent>
                    آیا اطمینان دارید از بازگشت این مورد؟
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUnarchiveDeleteCancel} color="primary">
                        خیر
                    </Button>
                    <Button onClick={handleUnarchiveConfirm} color="secondary">
                        بازگردانده شود
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openRecoveryPhraseDialog} onClose={handleRecoveryPhraseDialogClose}
                    style={{minWidth: '800px'}}>
                <DialogTitle>متن توضیح</DialogTitle>
                <DialogContent>
                    <TextField
                        dir="rtl"
                        placeholder="عبارت بازیابی را وارد کنید..."
                        style={{
                            width: '100%',
                            minWidth: '400px',
                            margin: '5px',
                            border: '1px solid #ccc',
                            outline: '2px solid #ccc',
                            outlineOffset: '2px',
                            borderRadius: '4px'
                        }}
                        value={recoveryPhrase}
                        onChange={(e) => setRecoveryPhrase(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRecoveryPhraseDialogClose} color="primary">
                        لغو
                    </Button>
                    <Button onClick={handleRecoveryPhraseDialogSave} color="primary">
                        اعمال
                    </Button>
                </DialogActions>
            </Dialog>
        </Table>
        {!(firstPage === true && lastPage === true) && (
            <Pagination id={'pagination'} size="lg">
                <Pagination.First disabled={currentPage === 1} onClick={() => handlePageChange(1)}/>
                <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}/>
                {pageItems}
                <Pagination.Next disabled={currentPage === totalPages}
                                 onClick={() => handlePageChange(currentPage + 1)}/>
                <Pagination.Last disabled={currentPage === totalPages}
                                 onClick={() => handlePageChange(totalPages)}/>
            </Pagination>
        )}
    </>);


}
const roleAdmin = 'ROLE_ADMIN';
const roleDonor = 'ROLE_DONOR';

class RoleUtil {

  checkUserRole(role: string): boolean {
        const authorities = sessionStorage.getItem('authorities');
        return authorities ? authorities.includes(role) : false;
    }

    isCurrentUserAdmin = () => {
        return this.checkUserRole(roleAdmin);
    }

    isCurrentUserDonor = () => {
        return this.checkUserRole(roleDonor);
    }
}

export default new RoleUtil();

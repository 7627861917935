// DonorAdd.js
import './DonorAdd.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';
import FormField from './FormField';
import {registerDonor} from "./api/DonorAddAPI";
import {validateEmail} from "../../lib/Validator";
import CustomSelect from "../../lib/CustomSelect";
import FormControl from "@mui/material/FormControl";

export default function DonorAdd(props) {
    const [formData, setFormData] = React.useState({
        user:{
            name: props?.value ?? '',
            familyName: props?.value ?? '',
            password: props?.value ?? '',
            email: props?.value ?? ''
        },
        city: props?.value ?? '',
        country: props?.value ?? '',
        workPlacePhone: props?.value ?? '',
        workPlace: props?.value ?? '',
        nationalCode: props?.value ?? '',
        education: props?.value ?? '',
        // educationType: props?.value ?? '',
        occupation: props?.value ?? ''
    });

    const [formErrors, setFormErrors] = React.useState({});
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleSubmit = async () => {
        const errors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                errors[key] = true;
            }
        });
        if (!validateEmail(formData.email)) {
            errors.email = true;
        }
        if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword = true;
        }
        console.log(errors);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        try {
            await registerDonor(formData); // Assuming registerDonor is an async function
            // Redirect or show success message
        } catch (error) {
            setAlertMessage(getAlertMessage(error.response?.status));
            setOpenSnackbar(true);
        }
    };

    const getAlertMessage = (status) => {
        switch (status) {
            case 500:
                return "مشکلی از سمت سرور پیش آمده!";
            case 502:
                return "ارتباط با سرور برقرار نشد!";
            case 400:
                return "چنین ایمیلی قبلا ثبت شده!";
            default:
                return "مشکلی در ثبت پیش آمد!";
        }
    };

    return (
        <>
            <div className="material-box material-box-green">
                <h1>ثبت مدیر جدید</h1>
            </div>
            <Box sx={{width: '999999px', maxWidth: '100%',}}>
                <FormField
                    label="نام"
                    name="name"
                    value={formData.name}
                    error={formErrors.name}
                    handleChange={handleChange}
                />
                <FormField
                    label="نام خانوادگی"
                    name="familyName"
                    value={formData.familyName}
                    error={formErrors.familyName}
                    handleChange={handleChange}
                />
                <br/>
                <FormField
                    label="رمز عبور"
                    name="password"
                    type="password"
                    value={formData.password}
                    error={formErrors.password}
                    handleChange={handleChange}
                />
                <FormField
                    label="تکرار رمز عبور"
                    name="confirmPassword"
                    type="password"
                    value={formData.confirmPassword}
                    error={formErrors.confirmPassword}
                    handleChange={handleChange}
                />
                <br/>
                <FormField
                    label="تلفن"
                    name="workPlacePhone"
                    value={formData.workPlacePhone}
                    error={formErrors.workPlacePhone}
                    handleChange={handleChange}
                    maxWidth='42%'
                />
                <br/>
                <FormField
                    label="آدرس ایمیل"
                    name="email"
                    value={formData.email}
                    error={formErrors.email}
                    handleChange={handleChange}
                    maxWidth='42%'
                />
                <br/>
                <FormField
                    label="استان"
                    name="country"
                    value={formData.country}
                    error={formErrors.country}
                    handleChange={handleChange}
                />
                <FormField
                    label="شهر"
                    name="city"
                    value={formData.city}
                    error={formErrors.city}
                    handleChange={handleChange}
                />
                <br/>
                <FormField
                    label="آدرس"
                    name="workPlace"
                    value={formData.workPlace}
                    error={formErrors.workPlace}
                    handleChange={handleChange}
                    maxWidth='42%'
                />
                <br/>
                <FormField
                    label="کد ملی"
                    name="nationalCode"
                    value={formData.nationalCode}
                    error={formErrors.nationalCode}
                    handleChange={handleChange}
                    maxWidth='42%'
                />
                <br/>
                <FormControl sx={{width: '9999999px', maxWidth: '42%', margin: 1}}>
                    <CustomSelect
                        options={
                            [
                                {
                                    label: 'متوسطه اول',
                                    enumName:'SCHOOL'
                                },
                                {
                                    label: 'متوسطه دوم',
                                    enumName:'HIGH_SCHOOL'
                                },
                                {
                                    label: 'فوق لیسانس',
                                    enumName:'MASTERS'
                                },
                                {
                                    label: 'دکترا',
                                    enumName:'PHD'
                                }
                            ]
                        }
                        onChange={(item) => {

                        }}
                        label="مقطع تحصیلی"
                    />
                </FormControl>
                <br/>
                <FormField
                    label="رشته تحصیلی"
                    name="education"
                    value={formData.education}
                    error={formErrors.education}
                    handleChange={handleChange}
                    maxWidth='42%'
                />
                <br/>
                <FormField
                    label="شغل"
                    name="occupation"
                    value={formData.occupation}
                    error={formErrors.occupation}
                    handleChange={handleChange}
                    maxWidth='42%'
                />

                <br/>
                <br/>
                <Button onClick={handleSubmit} variant="contained">
                    ثبت مدیر مرکز
                </Button>
            </Box>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                key={'bottom' + 'left'}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{width: '100%'}}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

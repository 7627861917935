import axios from "axios";
import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Button from '@mui/material/Button';
import {useLocation} from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {MDBIcon} from "mdbreact";
import CustomSelect from "../../lib/CustomSelect";
import FormControl from "@mui/material/FormControl";
import TabletRow from "./TabletRow";
import {MenuItem, Select, TextareaAutosize} from "@mui/material";
import {assignTabletId} from "./api/TabletAPI";

export default function Tablets(props) {
    const [tabletList, setTabletList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [firstPage, setFirstPage] = useState(true);
    const [lastPage, setLastPage] = useState(false);
    const [centers, setCenters] = useState([]);
    const [center, setCenter] = useState(null);
    const [inputTabletText, setInputTabletText] = useState('');
    const [identifierSpecified, setIdentifierSpecified] = useState(""); // State to track XXX value

    const [selectedRow, setSelectedRow] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const centerId = queryParams.get('centerId');


    const [centerDialogOpen, setCenterDialogOpen] = useState(false);
    const [tabletDialogOpen, setTabletDialogOpen] = useState(false);

    function handleSearchChange(event) {
        setSearchQuery(event.target.value);
    }

    const handleIdentifierSpecifiedChange = (event) => {
        let value = event.target.value;
        if (value === "0") value = "false";
        else if (value === "1") value = "true";
        else if (value === "-1") value = "";
        setIdentifierSpecified(event.target.value);
        getTabletLists(centerId, value);
    };


    function handleSearchSubmit(event) {
        event.preventDefault();
        setCurrentPage(1);
    }

    const handleTabletSaveAndClose = async () => {
        await assignTabletId(selectedRow.id, inputTabletText);
        setTabletDialogOpen(false);
        getTabletLists(centerId);
    }
    const handleCenterDialogClick = (row) => {
        setSelectedRow(row);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/centers-panel?page=0&size=9999`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        }).then(response => {
            const updatedCenters = [{
                "id": 0,
                "name": "بدون مرکز"
            }, ...response.data.content]; // Add 'no center' item to the response data
            setCenters(updatedCenters);
            setCenterDialogOpen(true);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });

    };
    const handleCenterDialogClose = () => {
        setCenterDialogOpen(false);
    };
    const handleTabletDialogClose = () => {
        setTabletDialogOpen(false);
    };

    const handleCenterDialogConfirm = () => {
        setCenter(null);
        const updatedRow = {
            ...selectedRow, center: center.id === 0 ? null : {id: center.id}
        };

        // Send a put request to update the user's first name
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/api/tablets/${selectedRow.id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(updatedRow),
        }).then((res) => {
            getTabletLists(centerId);
        });
        setCenterDialogOpen(false);
    };

    useEffect(() => {
        getTabletLists(centerId);
    }, [currentPage, searchQuery]); // Fetch the data when the component mounts

    ///////////////
    function handlePageChange(pageNumber) {
        setCurrentPage(pageNumber);
    }

    const pageItems = [];

    // Add the first page item
    pageItems.push(
        <Pagination.Item key={1} active={currentPage === 1} onClick={() => handlePageChange(1)}>
            {1}
        </Pagination.Item>
    );

    // Add the previous ellipsis if necessary
    if (currentPage > 3 && totalPages > 5) {
        pageItems.push(<Pagination.Ellipsis key="prev-ellipsis"/>);
    }

    // Add the pages between the first and last pages
    for (let page = Math.max(2, currentPage - 2); page <= Math.min(totalPages - 1, currentPage + 2); page++) {
        pageItems.push(
            <Pagination.Item key={page} active={currentPage === page} onClick={() => handlePageChange(page)}>
                {page}
            </Pagination.Item>
        );
    }

    // Add the next ellipsis if necessary
    if (totalPages - currentPage > 3 && totalPages > 5) {
        pageItems.push(<Pagination.Ellipsis key="next-ellipsis"/>);
    }

    // Add the last page item
    pageItems.push(
        <Pagination.Item key={totalPages} active={currentPage === totalPages}
                         onClick={() => handlePageChange(totalPages)}>
            {totalPages}
        </Pagination.Item>
    );

    /////////////

    function getTabletLists(centedId, identifierSpecified = "") {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/tablets-panel?page=${currentPage - 1}&size=${pageSize}&sort=id,desc&identifier.specified=${identifierSpecified}&searchField.contains=${encodeURIComponent(searchQuery)}&${centerId ? `&centerId.equals=${centerId}` : ''}`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        })
            .then((res) => {
                setTotalPages(res.data.totalPages);
                setTabletList(res.data.content);
                setFirstPage(res.data.first);
                setLastPage(res.data.last);
            })
            .catch((err) => console.log(err));
    }

    return (<>
        <div className="material-box material-box-green">
            <h1>لیست تبلت ها</h1>
        </div>
        <div style={{margin: 10}} className="input-group md-form form-sm form-1 pl-0">

                        <span className="input-group-text purple lighten-3" id="basic-text1">
                                 <MDBIcon className="text-white" icon="search" onClick={handleSearchSubmit}/>
                        </span>
            <input
                className="form-control my-0 py-1"
                type="text"
                placeholder="جست و جو در مدل، شناسه داخلی و Android ID"
                aria-label="Search"
                value={searchQuery}
                onChange={handleSearchChange}
                onSubmit={handleSearchSubmit}
            />
        </div>

        <Select
            style={{ marginLeft:10,maxWidth:400}}
            value={identifierSpecified}
            onChange={handleIdentifierSpecifiedChange}
            displayEmpty
        >
            <MenuItem disabled value="">
                <em>فیلتر شناسه تبلت‌ها</em>
            </MenuItem>
            <MenuItem value="-1">همه</MenuItem>
            <MenuItem value="1">دارای شناسه</MenuItem>
            <MenuItem value="0">فاقد شناسه</MenuItem>
        </Select>

        <Table id="tablecontent" className="material-table" striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>Android ID</th>
                <th>شناسه داخلی</th>
                <th>مدل</th>
                <th>تعداد کاربران ثبت شده</th>
                <th>نام مرکز</th>
                <th>عملیات</th>
            </tr>
            </thead>
            <tbody>
            {
                tabletList.map((row, idx) => (
                    <TabletRow
                        key={idx}
                        row={row}
                        handleTabletDialogClick={(row) => {
                            setInputTabletText(row.identifier);
                            setTabletDialogOpen(true);
                            setSelectedRow(row);
                        }}
                        handleCenterDialogClick={handleCenterDialogClick}
                    />
                ))
            }
            </tbody>
        </Table>
        {!(firstPage === true && lastPage === true) && (
            <Pagination id={'pagination'} size="lg">
                <Pagination.First disabled={currentPage === 1} onClick={() => handlePageChange(1)}/>
                <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}/>
                {pageItems}
                <Pagination.Next disabled={currentPage === totalPages}
                                 onClick={() => handlePageChange(currentPage + 1)}/>
                <Pagination.Last disabled={currentPage === totalPages}
                                 onClick={() => handlePageChange(totalPages)}/>
            </Pagination>
        )}
        <Dialog open={centerDialogOpen} onClose={handleCenterDialogClose} fullWidth maxWidth="md">
            <DialogTitle>تخصیص مرکز</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="dense">
                    <CustomSelect
                        options={centers.map(item => ({
                            id: item.id,
                            label: item.id === 0 ? item.name : `${item.name} ${item.country} - ${item.city}`
                        }))}
                        value={center}
                        onChange={setCenter}
                        label="تخصیص مرکز"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCenterDialogConfirm} color="primary">
                    تایید
                </Button>
                <Button onClick={handleCenterDialogClose} color="secondary">
                    لغو
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={tabletDialogOpen} onClose={handleTabletDialogClose} style={{minWidth: '800px'}}>
            <DialogTitle>شناسه داخلی</DialogTitle>
            <DialogContent>
                <TextareaAutosize
                    placeholder="شناسه داخلی را وارد کنید..."
                    style={{
                        width: '100%',
                        minWidth: '400px',
                        margin: '5px',
                        border: '1px solid #ccc',
                        outline: '2px solid #ccc',
                        outlineOffset: '2px',
                        borderRadius: '4px'
                    }}
                    value={inputTabletText}
                    onChange={(e) => setInputTabletText(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleTabletDialogClose} color="primary">
                    لغو
                </Button>
                <Button onClick={handleTabletSaveAndClose} color="primary">
                    اعمال
                </Button>
            </DialogActions>
        </Dialog>
    </>);


}
import './AddCenter.css'
import * as React from 'react';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import {useState} from "react";
import axios from 'axios';
import Snackbar from "@mui/material/Snackbar";
import {useNavigate} from "react-router-dom";
import Alert from '@mui/material/Alert';

export default function AddCenter(props) {
    const navigate = useNavigate();
    const [name, setName] = useState(props?.value ?? '');
    const [country, setCountry] = useState(props?.value ?? '');
    const [city, setCity] = useState(props?.value ?? '');

    const [isValidName, setIsValidName] = useState(true);
    const [isValidCountry, setIsValidCountry] = useState(true);
    const [isValidCity, setIsValidCity] = useState(true);

    function getAlertMessage(status) {
        if (status === 500)
            return "مشکلی از سمت سرور پیش آمده!";
        else if (status === 502)
            return "ارتباط با سرور برقرار نشد!";
        else if (status === 400)
            return "چنین ایمیلی قبلا ثبت شده!";
        return "مشکلی در ثبت پیش آمد!";
    }

    function handleClick() {
        var proceed = true;
        if (name == "") {
            setIsValidName(false);
            proceed = false;
        } else setIsValidName(true)
        if (country == "") {
            setIsValidCountry(false);
            proceed = false;
        } else setIsValidCountry(true)
        if (city == "") {
            setIsValidCity(false);
            proceed = false;
        } else setIsValidCity(true);
        if (!proceed) return;
        const requestData = {
            name: name,
            city: city,
            country: country
        };
        const url = `${process.env.REACT_APP_API_URL}/api/centers`;
        const headers = {
            'accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('TOKEN')}`,
        };
        axios.post(url, requestData, {headers})
            .then(response => {
                navigate("/panel");
            })
            .catch(error => {
                setState({
                    open: true,
                    alertMessage: getAlertMessage(error.response.status)
                });
            });
    }

    const [state, setState] = useState({
        open: false,
        alertMessage: 'مشکلی در ثبت پیش آمد!'
    });
    const {open, alertMessage} = state;
    const handleClose = () => {
        setState({...state, open: false});
    };
    return (<>
        <div className="material-box material-box-green">
            <h1>ثبت مرکز</h1>
        </div>
        <Box sx={{width: '999999px', maxWidth: '100%',}}>
            <FormControl sx={{width: '9999999px', maxWidth: '42%'}} error={!isValidName}>
                <InputLabel htmlFor="my-input">نام</InputLabel>
                <Input id="my-input" onInput={e => setName(e.target.value)} aria-describedby="my-helper-text"/>
                <FormHelperText id="my-helper-text">نام مرکز</FormHelperText>
            </FormControl>
            <br/>
            <FormControl sx={{width: '9999999px', maxWidth: '20%', margin: 1}} error={!isValidCountry}>
                <InputLabel htmlFor="my-input">نام استان</InputLabel>
                <Input id="my-input" onInput={e => setCountry(e.target.value)} aria-describedby="my-helper-text"/>
                <FormHelperText id="my-helper-text">نام استان استقرار مرکز</FormHelperText>
            </FormControl>
            <FormControl sx={{width: '9999999px', maxWidth: '20%', margin: 1}} error={!isValidCity}>
                <InputLabel htmlFor="my-input">نام شهر یا روستا</InputLabel>
                <Input id="my-input" onInput={e => setCity(e.target.value)} aria-describedby="my-helper-text"/>
                <FormHelperText id="my-helper-text">نام شهر یا روستا استقرار مرکز</FormHelperText>
            </FormControl>
            <br/>
            <br/>
            <br/>
            <Button onClick={handleClick} variant="contained">
                ثبت مرکز
            </Button>
        </Box>
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            open={open}
            onClose={handleClose}
            key={'bottom' + 'left'}
        >
            <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
                {alertMessage}
            </Alert>
        </Snackbar>
    </>);

}
import '../../assets/vendor/bootstrap/css/bootstrap.min.css'
import '../../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import './Login.css'
import {Helmet} from 'react-helmet'
import {useNavigate, useLocation} from 'react-router-dom';
import {forwardRef, useEffect, useId, useState} from 'react';
import AuthenticationService from "../../lib/AuthenticationService";
import queryString from 'query-string';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from "axios";
import RoleUtil from "../../lib/RoleUtil";
import {Tilt} from "react-tilt";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Login(props) {
    const [state, setState] = useState({
        open: false,
        alertMessage: 'نام کاربری یا رمز عبور اشتباه است'
    });
    const navigate = useNavigate();
    useEffect(() => {
        // Check if token is present in cache
        const token = sessionStorage.getItem("TOKEN");
        console.log('TOKEN',token)
        if (token) {
            // Redirect to panel page
            if (RoleUtil.isCurrentUserAdmin()){
                navigate("/dashboard");
            }else navigate("/desktop")
        }
    }, [navigate]);
    const {open, alertMessage} = state;

    const id = useId();
    const [email, setEmail] = useState(props?.value ?? '');
    const [password, setPassword] = useState(props?.value ?? '');
    const location = useLocation();

    function handleClick() {
        sessionStorage.clear();
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/authenticate',
            headers: {},
            data: {
                password: password,
                rememberMe: false,
                username: email
            }
        }).then(res => doLogin(res))
            .catch(err => doFail(err));
    }

    function doLogin(res) {
        const token = res.data.id_token;
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/api/account',
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then(res => {
            storeAccount(res.data)
            AuthenticationService.storeToken(token)
            const {redirectTo} = queryString.parse(location.search);
            if (redirectTo == null) {
                if (RoleUtil.isCurrentUserAdmin()){
                    navigate("/dashboard");
                }else navigate("/desktop")
            } else {
                navigate(redirectTo);
            }
        })
            .catch(err => console.log("there was error with getting profile", err));

    }

    function storeAccount(profile) {
        AuthenticationService.storeProfile(profile)
    }

    function doFail(err) {
        setState({
            open: true,
            alertMessage: getAlertMessage(err.response.status)
        });
    }

    function getAlertMessage(status) {
        if (status === 401)
            return "نام کاربری یا رمز عبور اشتباه است";
        else
            return "خطایی هنگام ورود رخ داده است";
    }

    const handleClose = () => {
        setState({...state, open: false});
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>داناک | ورود و عضویت</title>
            </Helmet>

            {
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">
                            <Tilt className="Tilt" options={{scale: 1.1}} style={{height: '100%', width: 250}}>

                                <div className="login100-pic">
                                    <img src={require('../../assets/logo-savad.png')}
                                         alt={"Logo"}/>
                                </div>
                            </Tilt>
                            <div className="login100-form validate-form">
                                <span className="login100-form-title">وارد شوید</span>
                                <div className="wrap-input100 validate-input" data-validate="ایمیل خود را وارد کنید ">
                                    <input id={id} value={email} onInput={e => setEmail(e.target.value)}
                                           className="input100" type="text" name="email" placeholder="ایمیل"/>
                                    <span className="focus-input100"/>
                                    <span className="symbol-input100">
                                        <i className="fa fa-envelope" aria-hidden="true"/>
                                    </span>
                                </div>
                                <div className="wrap-input100 validate-input" data-validate="رمز عبور خود را وارد کنید">
                                    <input id={id} value={password} onInput={e => setPassword(e.target.value)}
                                           className="input100" type="password" name="pass" placeholder="رمز عبور"/>
                                    <span className="focus-input100"/>
                                    <span className="symbol-input100">
                                        <i className="fa fa-lock" aria-hidden="true"/>
                                    </span>
                                </div>
                                <div className="container-login100-form-btn">
                                    <button onClick={handleClick}
                                            className="login100-form-btn">
                                        ورود
                                    </button>
                                    <a className="txt2" href="#">
                                        فراموشی رمز عبور
                                    </a>
                                    <div className="p-t-12"/>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                open={open}
                onClose={handleClose}
                key={'bottom' + 'left'}
            >
                <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Select,
    MenuItem,
    CircularProgress,
} from '@mui/material';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import './Dashboard.css';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import TabletIcon from '@mui/icons-material/Tablet';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HubIcon from '@mui/icons-material/Hub';
import PersianDigitUtil from '../../lib/PersianDigitUtil';
import CustomSelect from '../../lib/CustomSelect';
import PersianDateUtil from '../../lib/PersianDateUtil';

export default function Dashboard(props) {
    const [dashboardData, setDashboardData] = useState({
        cards: [],
        reports: { dates: [], counts: [] },
        centers: [],
    });
    const [center, setCenter] = useState(null);
    const [days, setDays] = useState({
        id: 30,
        label: '',
    });
    const [loading, setLoading] = useState(false);

    const [plotType, setPlotType] = useState('');

    useEffect(() => {
        getDashboard();
    }, [center, days]);

    function getDashboard() {
        setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/api/dashboard?days=${days ? days.id:30}&centerId=${center ? center.id : ''}`;

        axios({
            method: 'get',
            url,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`,
            },
        })
            .then((res) => {
                setDashboardData({
                    cards: [
                        {
                            title: 'کاربران تبلت',
                            value: PersianDigitUtil.formatBigNumber(res.data.numberOfUsers),
                            icon: <RememberMeIcon fontSize="large" />,
                        },
                        {
                            title: 'تبلت ها',
                            value: PersianDigitUtil.formatBigNumber(res.data.numberOfTablets),
                            icon: <TabletIcon fontSize="large" />,
                        },
                        {
                            title: 'مراکز',
                            value: PersianDigitUtil.formatBigNumber(res.data.numberOfCenters),
                            icon: <HubIcon fontSize="large" />,
                        },
                    ],
                    reports: formatReports(res.data.reports),
                    centers: res.data.centers,
                });
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function formatReports(reports) {
        if (!reports) return;

        const sortedReports = Object.keys(reports)
            .sort((a, b) => new Date(a) - new Date(b))
            .reduce((acc, key) => {
                acc[key] = reports[key];
                return acc;
            }, {});

        const reportDates = Object.keys(sortedReports).map((date) => {
            const persianDateObj = PersianDateUtil.getPersianDate(date);
            if (days.id === 7 || days.id === 3) {
                return `${persianDateObj.dayWeek} ${persianDateObj.day} ${persianDateObj.monthTitle}`;
            }
            return `${persianDateObj.day} ${persianDateObj.monthTitle}`;
        });
        const reportValues = Object.values(sortedReports);

        return {
            dates: reportDates,
            counts: reportValues.map(it=>it.count),
            completes: reportValues.map(it=>it.completed),
            children: reportValues.map(it=>it.children),
        };
    }

    const reportOptions = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    const reportCountChartData = {
        labels: dashboardData.reports ? dashboardData.reports.dates : [],
        datasets: [
            {
                label: 'گزارش های دریافتی',
                data: dashboardData.reports ? dashboardData.reports.counts : [],
                backgroundColor: '#a4e7b0',
                borderColor: '#4cba4c',
                borderWidth: 3,
            }
        ],
    };
    const reportCompletesChartData = {
        labels: dashboardData.reports ? dashboardData.reports.dates : [],
        datasets: [
            {
                label: 'یکانه‌های تکمیل شده',
                data: dashboardData.reports ? dashboardData.reports.completes : [],
                backgroundColor: '#77beef',
                borderColor: '#4a74dd',
                borderWidth: 3,
            }
        ],
    };
    const reportChildrenChartData = {
        labels: dashboardData.reports ? dashboardData.reports.dates : [],
        datasets: [
            {
                label: 'تعداد کودکان',
                data: dashboardData.reports ? dashboardData.reports.children : [],
                backgroundColor: '#ffe47d',
                borderColor: '#a48701',
                borderWidth: 3,
            }
        ],
    };
    const daysData = [
        {
            id: 30,
            label: '30 - سی روز گذشته',
        },
        {
            id: 15,
            label: '15 - پانزده روز گذشته',
        },
        {
            id: 7,
            label: '7 - هفته اخیر',
        },
        {
            id: 3,
            label: '3 - سه روز اخیر',
        },
    ];

    return (
        <div className="dashboard-container">
            <Grid container spacing={3}>
                {dashboardData.cards.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card elevation={3}>
                            <CardContent>
                                <Typography variant="h6" component="div" className="card-title">
                                    {item.title}
                                </Typography>
                                <div className="card-content">
                                    <div className="icon-container">{item.icon}</div>
                                    <Typography variant="h4" component="div" className="card-value">
                                        {item.value}
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <div className="chart-container">
                <div className="dashboard-select">
                    {dashboardData.centers && (
                        <CustomSelect
                            options={dashboardData.centers.map((item) => ({
                                id: item.id,
                                label: `${item.name} ${item.country} - ${item.city}`,
                            }))}
                            value={center}
                            onChange={setCenter}
                            label="نام مرکز"
                        />
                    )}
                </div>
                <div className="dashboard-select">
                    <CustomSelect options={daysData} value={days} onChange={setDays} label="بازه زمانی" />
                </div>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Bar data={reportCountChartData} options={reportOptions} />
                        <Bar data={reportCompletesChartData} options={reportOptions} />
                        <Bar data={reportChildrenChartData} options={reportOptions} />
                    </>
                )}
            </div>
        </div>
    );
}

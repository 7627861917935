class AlertUtil{
    getAlertMessage(status) {
        if (status === 200)
            return "ثبت با موفقیت انجام شد!";
        if (status === 500)
            return "مشکلی از سمت سرور پیش آمده!";
        else if (status === 502)
            return "ارتباط با سرور برقرار نشد!";
        else if (status === 400)
            return "چنین ایمیلی قبلا ثبت شده!";
        return "مشکلی در ثبت پیش آمد!";
    }
}
export default new AlertUtil();
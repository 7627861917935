import React, { useState } from 'react';
import {IconButton, Menu, MenuItem, Button, ListItemIcon, ListItemText} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Delete, Tablet} from "@mui/icons-material";

const CenterRow = ({
                       row,
                       idx,
                       editingIndex,
                       editedValue,
                       setEditedValue,
                       navigate,
                       handleDeleteCenterOpen,
                       handleEditClick,
                       handleSaveClick
                   }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <tr key={idx}>
            <td>{row.id}</td>
            <td>
                {editingIndex === idx ? (
                    <input
                        type="text"
                        value={editedValue}
                        onChange={(e) => setEditedValue(e.target.value)}
                    />
                ) : (
                    row.name
                )}
            </td>
            <td>{row.country}</td>
            <td>{row.city}</td>
            <td>{row.tabletCount}</td>
            <td>
                <IconButton onClick={handleClick}>
                    <MoreHorizIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {row.tabletCount > 0 ? (
                        <MenuItem onClick={() => navigate(`/tablets?centerId=${row.id}`)}>
                            <ListItemIcon>
                                <Tablet/>
                            </ListItemIcon>
                            <ListItemText primary="مشاهده تبلت‌ها"/>
                        </MenuItem>
                    ) : (
                        <MenuItem onClick={() => handleDeleteCenterOpen(row.id)}>
                            <ListItemIcon>
                                <Delete/>
                            </ListItemIcon>
                            <ListItemText primary="حذف مرکز"/>
                        </MenuItem>
                    )}
                </Menu>
            </td>
            <td>
                {editingIndex === idx ? (
                    <IconButton onClick={() => handleSaveClick(idx, row.id)}><SaveIcon /></IconButton>
                ) : (
                    <IconButton onClick={() => handleEditClick(idx, row.name)}><EditIcon /></IconButton>
                )}
            </td>
        </tr>
    );
};

export default CenterRow;

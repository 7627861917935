import './App.css';
import TableView from './pages/activity-table/TableView';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes, Route} from "react-router-dom";
import CardView from "./pages/activity-cards/CardView";
import Login from "./pages/login/Login";
import Panel from "./pages/panel/Panel";
import {useEffect} from "react";
import PrivateRoute from "./lib/AuthenticatedRoute";
import Logout from "./pages/logout/Logout";
import DownloadView from "./pages/download/DownloadView";
import DonorAdd from "./pages/donor-add/DonorAdd";
import DonorList from "./pages/donor-list/DonorList";
import Tablets from "./pages/tablets/Tablets";
import TabletUsers from "./pages/tablet-users/TabletUsers";
import ActivityProgress from "./pages/activity-progress/ActivityProgress";
import AddCenter from "./pages/add-center/AddCenter";
import ListCenter from "./pages/center-list/ListCenter";
import CenterDonor from "./pages/center-donor/CenterDonor";
import Dashboard from "./pages/dashboard/Dashboard";

function App() {

    useEffect(() => {
        window.process = {
            ...window.process,
        };
    }, []);
    const RedirectUrl = ({url}) => {
        useEffect(() => {
            window.location.href = url;
        }, [url]);

        return <h5>Loading...</h5>;
    };
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Login/>}/>
                <Route path="/activity-progress" element={<PrivateRoute><Panel page={<ActivityProgress/>}/></PrivateRoute>}/>
                <Route path="/add-center" element={<PrivateRoute><Panel page={<AddCenter/>}/></PrivateRoute>}/>
                <Route path="/add-donor" element={<PrivateRoute><Panel page={<DonorAdd/>}/></PrivateRoute>}/>
                <Route path="/center-donor" element={<PrivateRoute><Panel page={<CenterDonor/>}/></PrivateRoute>}/>
                <Route path="/d" element={<DownloadView/>}/>
                <Route path="/desktop" element={<PrivateRoute><Panel page={<TableView/>}/></PrivateRoute>}/>
                <Route path="/download" element={<DownloadView/>}/>
                <Route path="/dashboard" element={<PrivateRoute><Panel page={<Dashboard/>}/></PrivateRoute>}/>
                <Route path="/list-center" element={<PrivateRoute><Panel page={<ListCenter/>}/></PrivateRoute>}/>
                <Route path="/list-donor" element={<PrivateRoute><Panel page={<DonorList/>}/></PrivateRoute>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route path="/manual" element={<RedirectUrl url="/app-manual/manual.html"/>}/>
                <Route path="/mobile" element={<PrivateRoute><Panel page={<CardView/>}/></PrivateRoute>}/>
                <Route path="/panel" element={<PrivateRoute><Panel/></PrivateRoute>}/>
                <Route path="/tablet-users" element={<PrivateRoute><Panel page={<TabletUsers/>}/></PrivateRoute>}/>
                <Route path="/tablets" element={<PrivateRoute><Panel page={<Tablets/>}/></PrivateRoute>}/>
            </Routes>
        </div>
    );
}


export default App;
import axios from 'axios';

// Define your API endpoints and functions here
const BASE_URL = process.env.REACT_APP_API_URL;

export const registerDonor = async (formData) => {
    const url = `${BASE_URL}/api/donors/register`;
    const headers = {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('TOKEN')}`,
    };
    try {
        const response = await axios.post(url, formData, { headers });
        return response.data;
    } catch (error) {
        throw error;
    }
};

// FormField.js
import React from 'react';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

const FormField = ({ label, name, type = 'text',maxWidth = '20%', value, error, handleChange }) => {
    return (
        <FormControl sx={{ width: '9999999px', maxWidth: maxWidth, margin: 1 }} error={error}>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <Input
                id={name}
                type={type}
                name={name}
                value={value}
                onChange={handleChange}
                aria-describedby={`${name}-helper-text`}
            />
            <FormHelperText id={`${name}-helper-text`}>
                {label}
            </FormHelperText>
        </FormControl>
    );
};

export default FormField;

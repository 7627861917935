import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import './DownloadView.css';
import { Helmet } from 'react-helmet';
import { Tilt } from 'react-tilt';

export default function DownloadView(props) {
    const [downloadUrl, setDownloadUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getLastVersion();
    }, []);

    function getLastVersion() {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/versions/last`,
        })
            .then((res) => {
                const tag = res.data.tag;
                const downloadUrl = `${process.env.REACT_APP_APK_URL}/Danak-v${tag}.apk`;
                console.log('Initiating download...');
                console.log('Download URL:', downloadUrl);
                setDownloadUrl(downloadUrl);
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>داناک | دانلود آخرین نسخه</title>
            </Helmet>
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        <Tilt className="Tilt" options={{ scale: 1.1 }} style={{ height: '100%', width: 250 }}>
                            <div className="login100-pic">
                                <img src={require('../../assets/logo-savad.png')} alt="Logo" />
                            </div>
                        </Tilt>
                        <div className="login100-form validate-form">
              <span className="login100-form-title">
                چنانچه دانلود به صورت خودکار آغاز نشد روی لینک زیر بزنید
              </span>
                            <div className="container-login100-form-btn">
                                {isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <a href={downloadUrl} style={{fontSize:'30px'}} target="_blank" rel="noopener noreferrer" download>
                                        دانلود
                                    </a>
                                )}
                                <div className="p-t-12" />
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
import PersianDigitUtil from "./PersianDigitUtil";

class PersianDateUtil {
    getPersianDate(udate) {
        if (udate === null) {
            // Return all fields as zero
            return {
                "day": "۰۰",
                "month": "۰۰",
                "monthTitle": "",
                "year": "۰۰۰۰",
                "dayWeek": "",
                "hours": "۰۰",
                "minutes": "۰۰",
                "seconds": "۰۰"
            };
        }

        const dateObject = new Date(udate);
        // Adding 3 hours and 30 minutes
        dateObject.setHours(dateObject.getHours() + 3);
        dateObject.setMinutes(dateObject.getMinutes() + 30);

        return {
            "day": this.getDateFormat(dateObject, {"day": "2-digit"}),
            "month": this.getDateFormat(dateObject, {"month": "numeric"}),
            "monthTitle": this.getDateFormat(dateObject, {"month": "long"}),
            "year": this.getDateFormat(dateObject, {"year": "numeric"}),
            "dayWeek": this.getDateFormat(dateObject, {"weekday": "long"}),
            "hours": PersianDigitUtil.parsifyDigits(dateObject.getUTCHours()),
            "minutes": PersianDigitUtil.parsifyDigits(dateObject.getUTCMinutes()),
            "seconds": PersianDigitUtil.parsifyDigits(dateObject.getUTCSeconds())
        };
    }

    getDateFormat(uDate, option) {
        return new Intl.DateTimeFormat('fa-IR', option).format(uDate);
    }

    timeAgo(dateString) {
        const currentDate = new Date();
        const pastDate = new Date(dateString);

        const timeDifference = currentDate.getTime() - pastDate.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(weeks / 4.345); // Average number of weeks in a month

        const remainingSeconds = seconds % 60;
        const remainingMinutes = minutes % 60;
        const remainingHours = hours % 24;
        const remainingDays = days % 7;

        const output = [];
        if (months > 0) output.push(`${months} ماه`);
        if (weeks > 0) output.push(`${weeks} هفته`);
        if (remainingDays > 0) output.push(`${remainingDays} روز`);
        if (remainingHours > 0) output.push(`${remainingHours} ساعت`);
        if (remainingMinutes > 0) output.push(`${remainingMinutes} دقیقه`);
        if (remainingSeconds > 0) output.push(`${remainingSeconds} ثانیه`);

        return output.join(', ') + ' پیش';
    }
}

export default new PersianDateUtil();

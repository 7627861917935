import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HubIcon from "@mui/icons-material/Hub";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import {useNavigate} from "react-router-dom";
import {NumbersOutlined} from "@mui/icons-material";

const TabletRow = ({row, key, handleTabletDialogClick, handleCenterDialogClick}) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <tr key={key}>
            <td>{row.id}</td>
            <td>{row.name}</td>
            <td>{row.identifier}</td>
            <td>{row.model}</td>
            <td>{row.numberOfUsers}</td>
            <td>{row.center ? (row.center.name + ' - ' + row.center.city + ' - ' + row.center.country) : ''}</td>
            <td>

                <IconButton onClick={handleClick}>
                    <MoreHorizIcon/>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {row.numberOfUsers > 0 &&
                        <MenuItem onClick={() => {
                            navigate(`/tablet-users?tabletId=${row.id}&aid=${row.name}&ide=${row.identifier ?? ''}`);
                            handleClose();
                        }}>
                            <ListItemIcon>
                                <RememberMeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="مشاهده کاربران در این تبلت"/>
                        </MenuItem>
                    }
                    <MenuItem onClick={() => {
                        handleCenterDialogClick(row);
                        handleClose();
                    }}>
                        <ListItemIcon>
                            <HubIcon/>
                        </ListItemIcon>
                        <ListItemText primary="تخصیص به مرکز"/>
                    </MenuItem>
                    <MenuItem onClick={()=> {
                        handleTabletDialogClick(row);
                        handleClose();
                    }}>
                        <ListItemIcon>
                            <NumbersOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="تعیین شناسه داخلی"/>
                    </MenuItem>
                </Menu>
            </td>
        </tr>
    );
};

export default TabletRow;

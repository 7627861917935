import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {ArchiveOutlined, ArrowUpward, History, HistoryToggleOffOutlined} from '@mui/icons-material';
import {useNavigate} from "react-router-dom"; // Import the icons you need

const TabletUserRow = ({row, key, persianDate, handleArchiveClick, handleUnArchiveClick,handleAssignRecoveryPhraseClick}) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <tr key={key} style={{background: row.archived ? 'lightcoral' : 'inherit'}}>
            <td>{row.id}</td>
            <td>{row.firstName}</td>
            <td>{row.lastName}</td>
            <td>{row.tablet.name}</td>
            <td>{row.tablet.identifier}</td>
            <td>{`${persianDate.year}/${persianDate.month}/${persianDate.day}`}</td>
            <td>{`${persianDate.hours}:${persianDate.minutes}:${persianDate.seconds}`}</td>
            <td>{row.recoveryPhrase}</td>
            <td>
                <IconButton onClick={handleClick}>
                    <MoreHorizIcon/>
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem
                        onClick={() => navigate(`/activity-progress?userId=${row.id}&name=${row.firstName}&lname=${row.lastName}&tid=${row.tablet.id}&aid=${row.tablet.name}&ide=${row.tablet.identifier ?? ''}`)}>
                        <HistoryToggleOffOutlined>
                            <History/>
                        </HistoryToggleOffOutlined>
                        <ListItemText primary="مشاهده فعالیت های کاربر"/>
                    </MenuItem>
                    <MenuItem
                        onClick={handleAssignRecoveryPhraseClick}>
                        <ListItemIcon>
                            <History/>
                        </ListItemIcon>
                        <ListItemText primary="تخصیص عبارت بازیابی"/>
                    </MenuItem>
                    {row.archived ? <MenuItem
                        onClick={handleUnArchiveClick}
                    >
                        <ListItemIcon>
                            <ArrowUpward/>
                        </ListItemIcon>
                        <ListItemText primary="از آرشیو خارج کردن کاربر"/>
                    </MenuItem> : <MenuItem
                        onClick={handleArchiveClick}
                    >
                        <ListItemIcon>
                            <ArchiveOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="آرشیو کاربر"/>
                    </MenuItem>}
                </Menu>
            </td>
        </tr>
    );
};

export default TabletUserRow;

import './ListCenter.css'
import Table from "react-bootstrap/Table";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import React, {useEffect, useState} from "react";
import axios from "axios";
import AlertUtil from "../../lib/AlertUtil";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CenterRow from "./CenterRow";

export default function ListCenter(props) {
    const navigate = useNavigate();
    const [centerList, setCenterList] = useState([])
    const [editingIndex, setEditingIndex] = useState(-1); // Track the index of the row being edited
    const [editedValue, setEditedValue] = useState(""); // Track the edited value
    const [deleteCenterOpen, setDeleteCenterOpen] = useState(false);
    const [deleteCenterId, setCenterDeleteId] = useState(null);

    const handleDeleteCenterOpen = (id) => {
        setCenterDeleteId(id);
        setDeleteCenterOpen(true);
    };
    const deleteCenterHandleClose = () => {
        setDeleteCenterOpen(false);
    };
    const deleteCenterHandleDelete = () => {
        // Send the DELETE request to the specified URL
        // You can use fetch or any other library for making API calls
        if (deleteCenterId) {
            axios({
                method: 'DELETE',
                url: `${process.env.REACT_APP_API_URL}/api/centers/${deleteCenterId}`,
                headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
            }).then(response => {
                refreshProjectList();
            }).catch(error => {
                console.error('Error fetching data:', error);
            });
        }
        // Close the confirmation dialog
        deleteCenterHandleClose();
    };

    const [state, setState] = useState({
        open: false,
        alertMessage: 'مشکلی در ثبت پیش آمد!',
        severity: 'error'
    });
    const {open, alertMessage,severity} = state;
    const handleClose = () => {
        setState({...state, open: false});
    };

    useEffect(() => {
        refreshProjectList();
    }, []); // Fetch the data when the component mounts
    function refreshProjectList() {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/centers-panel?page=0&size=9999`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        })
            .then((res) => {
                setCenterList(res.data.content);
            })
            .catch((err) => console.log(err));
    }
    // Handle editing when a user clicks on the cell
    const handleEditClick = (idx, value) => {
        setEditingIndex(idx);
        setEditedValue(value);
    };
    // Handle saving the edited value
    const handleSaveClick = (idx, entityId) => {
        // Prepare the updated user object
        const updatedUser = { id: entityId, name: editedValue };

        // Send a PATCH request to update the user's first name
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/api/centers/${updatedUser.id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(updatedUser),
        })
            .then((res) => {
                setState({
                    open: true,
                    alertMessage: AlertUtil.getAlertMessage(res.status),
                    severity: 'success'
                });
                const updatedCenterList = [...centerList];
                updatedCenterList[idx].name = editedValue;
                setCenterList(updatedCenterList);

                // Reset the editing state
                setEditingIndex(-1);
            })
            .catch((error) => {
                setState({
                    open: true,
                    alertMessage: AlertUtil.getAlertMessage(error.response.status),
                    severity: 'error'
                });
            });
    };
    return (<>
        <div className="material-box material-box-green">
            <h1>لیست مراکز</h1>
        </div>
        <Table id="tablecontent" striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>نام</th>
                <th>استان</th>
                <th>شهر یا روستا</th>
                <th>تعداد تبلت</th>
                <th>عملیات</th>
                <th>ویرایش نام</th>
            </tr>
            </thead>
            <tbody>
            {centerList.map((row, idx) => (
                <CenterRow
                    key={idx}
                    row={row}
                    idx={idx}
                    editingIndex={editingIndex}
                    editedValue={editedValue}
                    setEditedValue={setEditedValue}
                    navigate={navigate}
                    handleDeleteCenterOpen={handleDeleteCenterOpen}
                    handleEditClick={handleEditClick}
                    handleSaveClick={handleSaveClick}
                />
            ))}
            </tbody>
        </Table>
        <Dialog open={deleteCenterOpen} onClose={deleteCenterHandleClose}>
            <DialogContent>
                <DialogContentText>
                    آیا از حذف این رابطه اطمینان دارید؟
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={deleteCenterHandleClose} color="primary">
                    خیر
                </Button>
                <Button  onClick={deleteCenterHandleDelete}  color="primary">
                    بله
                </Button>
            </DialogActions>
        </Dialog>
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            open={open}
            onClose={handleClose}
            key={'bottom' + 'left'}
        >
            <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
                {alertMessage}
            </Alert>
        </Snackbar>
    </>);

}
import './CenterDonor.css'
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import LinkIcon from '@mui/icons-material/Link';
import Table from "react-bootstrap/Table";
import CustomSelect from "../../lib/CustomSelect";
import DialogContentText from '@material-ui/core/DialogContentText';

export default function CenterDonor(props) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [centerDonor, setDonorCenter] = useState([]);
    const [donors, setDonors] = useState([]);
    const [centers, setCenters] = useState([]);
    const [center, setCenter] = useState(null);
    const [donor, setDonor] = useState(null);
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const handleOpen = (id) => {
        setDeleteId(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleDelete = () => {
        // Send the DELETE request to the specified URL
        // You can use fetch or any other library for making API calls
        if (deleteId) {
            axios({
                method: 'DELETE',
                url: `${process.env.REACT_APP_API_URL}/api/center-donors/${deleteId}`,
                headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
            }).then(response => {
                refreshProjectList();
            }).catch(error => {
                console.error('Error fetching data:', error);
            });
        }
        // Close the confirmation dialog
        handleClose();
    };
    useEffect(() => {
        refreshProjectList();
    }, []); // Fetch the data when the component mounts
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleDialogConfirm = () => {
        console.log('confirm', center, donor)
        if (centers.length <= 0 || donors.length <= 0 || !center || !donor) return;
        const updatedRow = {
            center: {
                id: center.id
            },
            donor: {
                id: donor.id
            }
        };

        // Send a PATCH request to update the user's first name
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/api/center-donors`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(updatedRow),
        }).then((res) => {
            refreshProjectList();
        });
        setDialogOpen(false);
    };

    const handleDialogClick = () => {
        setCenter(null);
        setDonor(null);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/centers-panel?page=0&size=9999`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        }).then(response => {
            setCenters(response.data.content);
            setDialogOpen(true);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/api/donors/list?page=0&size=9999`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        }).then(response => {
            setDonors(response.data.content);
            setDialogOpen(true);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });

    };

    function refreshProjectList() {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/center-donors-table`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        })
            .then((res) => {
                setDonorCenter(res.data);
            })
            .catch((err) => console.log(err));
    }

    return (<>
        <div className="material-box material-box-green">
            <h1>تخصیص مدیر به مرکز</h1>
        </div>
        <Button
            onClick={() => handleDialogClick()}
            variant="contained">اضافه کردن رابطه جدید</Button>
        <Table id="tablecontent" striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>شناسه یکتای مرکز</th>
                <th>نام مرکز</th>
                <th>استان مرکز</th>
                <th>شهر یا روستای مرکز</th>
                <th>شناسه یکتای مدیر</th>
                <th>نام مدیر</th>
                <th>استان مدیر</th>
                <th>شهر مدیر</th>
            </tr>
            </thead>
            <tbody>
            {centerDonor.map((row, idx) => (
                <tr key={idx}>
                    <td>{row.id}</td>
                    <td>
                        {row.center.id}
                    </td>
                    <td>
                        {row.center.name}
                    </td>
                    <td>
                        {row.center.country}
                    </td>
                    <td>
                        {row.center.city}
                    </td>
                    <td>
                        {row.donor.id}
                    </td>
                    <td>
                        {row.donor.user.firstName} {row.donor.user.lastName}
                    </td>
                    <td>
                        {row.donor.country}
                    </td>
                    <td>
                        {row.donor.city}
                    </td>
                    <td>
                        <Button className="history-button" onClick={() => handleOpen(row.id)} variant="contained">
                            حذف این رابطه
                        </Button>

                        <Dialog open={open} onClose={handleClose}>
                            <DialogContent>
                                <DialogContentText>
                                    آیا از حذف این رابطه اطمینان دارید؟
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    خیر
                                </Button>
                                <Button  onClick={ handleDelete}  color="primary">
                                    بله
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
        <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="md">
            <DialogTitle>رابطه جدید</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="dense">
                    <CustomSelect
                        options={centers.map(item => ({
                            id: item.id,
                            label: `${item.name} ${item.country} - ${item.city}`
                        }))}
                        value={center}
                        onChange={setCenter}
                        label="نام مرکز"
                    />
                </FormControl>
                <LinkIcon style={{margin: '8px'}}/>

                <FormControl fullWidth margin="normal">
                    <CustomSelect
                        options={donors.map(item => ({
                            id: item.id,
                            label: `${item.user.firstName} ${item.user.lastName} - ${item.country} - ${item.city}`
                        }))}
                        value={donor}
                        onChange={setDonor}
                        label="نام مدیر"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogConfirm} color="primary">
                    تایید
                </Button>
                <Button onClick={handleDialogClose} color="secondary">
                    لغو
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}
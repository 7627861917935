class PersianDigitUtil {

    /**
     * Shorten number to thousands, millions, billions, etc.
     * http://en.wikipedia.org/wiki/Metric_prefix
     *
     * @param {number} num Number to shorten.
     * @param {number} [digits=0] The number of digits to appear after the decimal point.
     * @returns {string|number}
     *
     * @example
     * // returns '12.5k'
     * shortenLargeNumber(12543, 1)
     *
     * @example
     * // returns '-13k'
     * shortenLargeNumber(-12567)
     *
     * @example
     * // returns '51M'
     * shortenLargeNumber(51000000)
     *
     * @example
     * // returns 651
     * shortenLargeNumber(651)
     *
     * @example
     * // returns 0.12345
     * shortenLargeNumber(0.12345)
     */
    formatBigNumber(num) {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        return num;
    }

    parsifyDigits(number) {
        const digits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
        return String(number)
            .padStart(2, '0')
            .replace(/[0-9]/g, (digit) => digits[parseInt(digit)]);
    }
}

export default new PersianDigitUtil();

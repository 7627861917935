import {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from "axios";
import './CardView.css'

export default function CardView(props) {


    const [userList, setUserList] = useState([])

    useEffect(() => {
        refreshProjectList();
    }, [])

    function refreshProjectList() {
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/api/all-activities-by-user',
            headers: {Authorization: 'Bearer ' + sessionStorage.getItem('TOKEN')},
        }).then(res => setUserList(res.data))
            .catch(err => console.log(err))
    }

    return (
        <>
            <div id={'cardcontent'}>
                {userList.map((value, index) =>
                    <Card className='text-center d-inline-block' style={{margin: '1rem', width: '18rem'}}>
                        <Card.Header>{value.firstName} {value.lastName} 👤</Card.Header>
                        <ListGroup variant="flush">
                            {value.userActivities.map(activity =>
                                <ListGroup.Item style={{textAlign: 'left'}}>
                                    📋<b> List name: </b>{activity.displayListName}<br/>
                                    📱<b> All units: </b> {activity.totals}<br/>
                                    ✅<b> Completed units: </b>{activity.completes}</ListGroup.Item>
                            )}
                        </ListGroup>
                    </Card>
                )}

                <br/>
                <br/>
                <br/>
            </div>
        </>
    )

}
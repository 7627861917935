import axios from "axios";

export const assignTabletId = async (id, tabletId) => {
    const body = {
        id: id,
        identifier : tabletId === "" ? null : tabletId
    }
    try {
        const response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/api/tablets/${id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(body),
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export default function CustomSelect({ className, options, value, onChange, label }) {
    return (
        <Autocomplete
            className={className}
            options={options}
            getOptionLabel={(option) => `${option.label}`}
            value={value}
            onChange={(event, newValue) => {
                onChange(newValue)
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} />
            )}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.label, inputValue);
                const parts = parse(option.label, matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                  {part.text}
                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    );
}
import './DonorList.css'
import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from "react";
import axios from "axios";

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import AlertUtil from "../../lib/AlertUtil";

export default function DonorList(props) {
    const [userList, setUserList] = useState([])
    const [editingIndex, setEditingIndex] = useState(-1); // Track the index of the row being edited
    const [editedValue, setEditedValue] = useState(""); // Track the edited value
    const [state, setState] = useState({
        open: false,
        alertMessage: 'مشکلی در ثبت پیش آمد!',
        severity: 'error'
    });
    const {open, alertMessage,severity} = state;
    const handleClose = () => {
        setState({...state, open: false});
    };

    useEffect(() => {
        refreshProjectList();
    }, []); // Fetch the data when the component mounts
    function refreshProjectList() {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/api/donors/list?page=0&size=9999`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        })
            .then((res) => {
                setUserList(res.data.content);
            })
            .catch((err) => console.log(err));
    }
    // Handle editing when a user clicks on the cell
    const handleEditClick = (idx, value) => {
        setEditingIndex(idx);
        setEditedValue(value);
    };
    // Handle saving the edited value
    const handleSaveClick = (idx, entityId) => {
        // Prepare the updated user object
        const updatedUser = { id: entityId, name: editedValue };

        // Send a PATCH request to update the user's first name
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/api/donors/${updatedUser.id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(updatedUser),
        })
            .then((res) => {
                setState({
                    open: true,
                    alertMessage: AlertUtil.getAlertMessage(res.status),
                    severity: 'success'
                });
                // Update the user's first name in the userList state if the server request is successful
                const updatedUserList = [...userList];
                updatedUserList[idx].name = editedValue;
                setUserList(updatedUserList);

                // Reset the editing state
                setEditingIndex(-1);
            })
            .catch((error) => {
                setState({
                    open: true,
                    alertMessage: AlertUtil.getAlertMessage(error.response.status),
                    severity: 'error'
                });
            });
    };


    return (<>
        <div className="material-box material-box-green">
            <h1>لیست مدیران</h1>
        </div>
        <Table id="tablecontent" striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>نام</th>
                <th>نام خانوادگی</th>
                <th>ایمیل</th>
                <th>استان</th>
                <th>شهر یا روستا</th>
            </tr>
            </thead>
            <tbody>
            {userList.map((row, idx) => (
                <tr key={idx}>
                    <td>{row.id}</td>
                    <td>{row.user.firstName}</td>
                    <td>{row.user.lastName}</td>
                    <td>{row.user.login}</td>
                    <td>{row.country}</td>
                    <td>{row.city}</td>
                </tr>
            ))}
            </tbody>
        </Table>
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            open={open}
            onClose={handleClose}
            key={'bottom' + 'left'}
        >
            <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
                {alertMessage}
            </Alert>
        </Snackbar>
    </>)
}
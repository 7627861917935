import React, {useEffect, useState} from 'react';
import axios from "axios";
import './TableView.css'
import Pagination from 'react-bootstrap/Pagination';
import {MDBCol, MDBIcon} from "mdbreact";
import '../../lib/RoleUtil'
import CustomSelect from "../../lib/CustomSelect";
import PersianDateUtil from "../../lib/PersianDateUtil";
import RoleUtil from "../../lib/RoleUtil";
import CircularProgress from '@mui/material/CircularProgress';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextareaAutosize
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
// import PaginationComponent from "../../lib/PaginationComponent";

export default function TableView(props) {


    const [userList, setUserList] = useState([])
    const [wholeLoading, setWholeLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [centerId, setCenterId] = useState('');
    const [centers, setCenters] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [expandedRows, setExpandedRows] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [inputText, setInputText] = useState('');
    const [savingIdx, setSavingIdx] = useState(null);
    const [snackState, setSnackState] = useState({
        snackOpen: false,
        alertMessage: 'مشکلی در ثبت پیش آمد!',
        severity: 'error'
    });
    const {snackOpen, alertMessage, severity} = snackState;
    const [excelLoading, setExcelLoading] = useState(false);
    const [excelButtonDisabled, setExcelButtonDisabled] = useState(false);
    const handleOpenDialog = (idx, desc) => {
        setInputText(desc);
        setIsDialogOpen(true);
        setSavingIdx(idx);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSavingIdx(null);
    };

    function getAlertMessage(status) {
        if (status === 500)
            return "مشکلی از سمت سرور پیش آمده!";
        else if (status === 502)
            return "ارتباط با سرور برقرار نشد!";
        else if (status === 400)
            return "چنین ایمیلی قبلا ثبت شده!";
        else if (status === 200)
            return "عملیات با موفقیت انجام شد.";
        return "مشکلی در ثبت پیش آمد!";
    }

    const handleSaveAndClose = () => {
        // Call your save function here
        handleSaveDescription(inputText, savingIdx);
        setUserList(userList.map(user => {
            if (user.tabletUserId === savingIdx) {
                return {...user, tabletUserDescription: inputText}; // Update the tabletUserDescription
            }
            return user;
        }))
        setIsDialogOpen(false);
        setSavingIdx(null);
    };

    const handleSaveDescription = (description, userId) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/api/tablet-user-set-description/${userId}`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
            data: description
        })
            .then((res) => {
                setSnackState({
                    snackOpen: true,
                    alertMessage: getAlertMessage(res.status),
                    severity: 'success'
                });
            })
            .catch((error) =>
                setSnackState({
                    snackOpen: true,
                    alertMessage: getAlertMessage(error.response.status),
                    severity: 'error'
                })
            );
    };
    const toggleRowExpansion = (index) => {
        if (expandedRows.includes(index)) {
            setExpandedRows(expandedRows.filter(i => i !== index));
        } else {
            setExpandedRows([...expandedRows, index]);
        }
    };

    let initialDay = {
        id: 30,
        label: '30 روز - یک ماه گذشته',
    };
    const [days, setDays] = useState(initialDay);
    useEffect(() => {
        const handlePopState = () => {
            const url = new URL(window.location.href);
            const pageParam = url.searchParams.get('page');
            const page = parseInt(pageParam, 10) || 1;
            setCurrentPage(page);
        };

        window.addEventListener('popstate', handlePopState);


        const url = new URL(window.location.href);
        const pageParam = url.searchParams.get('page');
        const page = parseInt(pageParam, 10) || 1; // Default to page 1 if no valid page number is found
        setCurrentPage(page);
        refreshProjectList(pageSize, centerId, days);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [currentPage, searchQuery]);

    const daysData = [
        {
            id: 180,
            label: '180 روز - شش ماه گذشته'
        },
        {
            id: 90,
            label: '90 روز - سه ماه گذشته',
        },
        {
            id: 30,
            label: '30 روز - یک ماه گذشته',
        }
    ];


    function handlePageChange(pageNumber) {
        setCurrentPage(pageNumber);
        const url = new URL(window.location.href);
        url.searchParams.set('page', pageNumber);
        window.history.pushState({page: pageNumber}, '', url);
    }

    const pageItems = [];

    pageItems.push(
        <Pagination.Item key={1} active={currentPage === 1} onClick={() => handlePageChange(1)}>
            {1}
        </Pagination.Item>
    );

    if (currentPage > 3 && totalPages > 5) {
        pageItems.push(<Pagination.Ellipsis key="prev-ellipsis"/>);
    }

    for (let page = Math.max(2, currentPage - 2); page <= Math.min(totalPages - 1, currentPage + 2); page++) {
        pageItems.push(
            <Pagination.Item key={page} active={currentPage === page} onClick={() => handlePageChange(page)}>
                {page}
            </Pagination.Item>
        );
    }

    if (totalPages - currentPage > 3 && totalPages > 5) {
        pageItems.push(<Pagination.Ellipsis key="next-ellipsis"/>);
    }

    pageItems.push(
        <Pagination.Item key={totalPages} active={currentPage === totalPages}
                         onClick={() => handlePageChange(totalPages)}>
            {totalPages}
        </Pagination.Item>
    );

    function refreshProjectList(ps, cid, numberOfDays) {
        setWholeLoading(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/centers?page=0&size=9999`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        })
            .then((res) => {
                setCenters(res.data);
                setCenterId(cid);
            })
            .catch((err) => console.log(err));

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/activities-page?page=${currentPage - 1}&size=${ps}&sort=maxActivityId,desc&archived.equals=false&activitySearchField.contains=${searchQuery}&centerId.equals=${cid}&days=${numberOfDays.id}`,
            // url: `${process.env.REACT_APP_API_URL}/api/all-activities-page?page=${currentPage - 1}&size=${ps}&sort=ua.id,desc&search=${searchQuery}&centerId=${cid}&days=${numberOfDays.id}`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        })
            .then((res) => {
                setUserList(res.data.content);
                if (res.data.content.length < ps && !searchQuery) {
                    setTotalPages(1);
                } else {
                    setTotalPages(Math.max(1, Math.ceil(res.data.totalElements / ps)));
                }
                setWholeLoading(false);
                setCenterId(cid);
            })
            .catch((err) => console.log(err));
    }

    function handleSearchChange(event) {
        setSearchQuery(event.target.value);
    }

    function handleSearchSubmit(event) {
        event.preventDefault();
        setCurrentPage(1);
    }

    function getChanges(activities) {
        if (!activities || activities.length === 0) {
            return ""; // If activities array is empty or not provided
        }
        return activities.reduce((total, activity) => total + activity.lastChange, 0);
    }

    function getActivityTime(activities) {
        if (!activities || activities.length === 0) {
            return ""; // If activities array is empty or not provided
        }

        const item = activities.reduce((highestIdItem, currentItem) => {
            return currentItem.id > highestIdItem.id ? currentItem : highestIdItem;
        });
        let persianDate = PersianDateUtil.getPersianDate(item.createTimeStamp);
        return `${persianDate.year}/${persianDate.month}/${persianDate.day} ${persianDate.hours}:${persianDate.minutes}`;
    }

    function exportToExcel() {
        setExcelLoading(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/email-csv`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        })
            .then((res) => {
                setSnackState({
                    snackOpen: true,
                    alertMessage: getAlertMessage(res.status),
                    severity: 'success'
                });
                setExcelLoading(false);
                setExcelButtonDisabled(true)
            })
            .catch((error) => {
                setSnackState({
                    snackOpen: true,
                    alertMessage: getAlertMessage(error.status),
                    severity: 'error'
                });
                setExcelLoading(false);
            });
    }

    const handleSnackClose = () => {
        setSnackState({...snackState, snackOpen: false});
    };

    return (
        <>
            <MDBCol md="6">
                <div className="searchContainer my-0 py-1">
                    <div style={{minWidth: '820px'}} className="input-group">
                        <input
                            className="form-control "
                            type="text"
                            placeholder="جست و جو در نام، نام خانوادگی و تبلت"
                            aria-label="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            onSubmit={handleSearchSubmit}
                        />
                        <span className="input-group-text purple lighten-3" id="basic-text1">
                          <MDBIcon className="text-white" icon="search" onClick={handleSearchSubmit}/>
                        </span>
                    </div>
                    {centers && (
                        <CustomSelect className="selectt"
                                      options={centers.map((item) => ({
                                          id: item.id,
                                          label: `${item.name} ${item.country} - ${item.city}`,
                                      }))}
                                      onChange={(item) => {
                                          if (!item) setCenterId('')
                                          else setCenterId(item.id);
                                          if (item)
                                              refreshProjectList(pageSize, item.id, days);
                                          else
                                              refreshProjectList(pageSize, '', days);
                                      }}
                                      label="نام مرکز"
                        />
                    )}
                    <CustomSelect className="selectt" options={daysData} value={days} onChange={(item) => {
                        if (item) setDays(item)
                        else setDays(initialDay);
                        if (item)
                            refreshProjectList(pageSize, centerId, item);
                        else
                            refreshProjectList(pageSize, centerId, initialDay);

                    }}
                                  label="بازه زمانی"/>
                </div>
                {RoleUtil.isCurrentUserAdmin() &&
                    (
                        (excelLoading ? (
                            <CircularProgress/> // Show circular progress if loading is true
                        ) : (
                            <button
                                className="btn btn-primary selectt"
                                onClick={exportToExcel}
                                disabled={excelButtonDisabled} // Disable the button if buttonDisabled is true
                            >
                                💾 ارسال کل داده در ایمیل
                            </button>
                        ))

                    )
                }
            </MDBCol>
            {wholeLoading ? <CircularProgress className="table-view-circular-progress"/> :
                <div>
                    <TableContainer style={{overflow: 'auto'}}>
                        <Table className="striped-table" style={{wordWrap: "break-word", tableLayout: "fixed"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="bold-header" style={{width: '7%'}}>تغییر</TableCell>
                                    <TableCell className="bold-header" style={{width: '11%'}}>زمان دریافت
                                        گزارش</TableCell>
                                    <TableCell className="bold-header" style={{width: '15%'}}>نام مرکز</TableCell>
                                    <TableCell className="bold-header" style={{width: '10%'}}>شهر یا روستای
                                        مرکز</TableCell>
                                    <TableCell className="bold-header" style={{width: '10%'}}>استان مرکز</TableCell>
                                    <TableCell className="bold-header" style={{width: '15%'}}>نام خانوادگی</TableCell>
                                    <TableCell className="bold-header" style={{width: '15%'}}>نام</TableCell>
                                    <TableCell className="bold-header" style={{width: '8%'}}>شناسه</TableCell>
                                    <TableCell className="bold-header" style={{width: '14%'}}>تبلت</TableCell>
                                    <TableCell className="bold-header"
                                               style={{width: '5%'}}></TableCell> {/* Empty cell for the chevron icon */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userList.map((user, idx) => {
                                    let activityProgressHref = `/activity-progress?userId=${user.tabletUserId}&name=${user.firstName}&lname=${user.lastName}&tid=${user.tabletId}&aid=${user.tabletName}&ide=${user.tabletIdentifier ?? ''}`;
                                    let tabletUsersHref = `/tablet-users?tabletId=${user.tabletId}&aid=${user.tabletName}&ide=${user.tabletIdentifier ?? ''}`;
                                    let centerHref = `/tablets?centerId=${user.center?.id ?? ""}`;
                                    let lastChanges = getChanges(user.userActivities);
                                    return (
                                        <React.Fragment key={idx}>
                                            <TableRow>
                                                <TableCell
                                                    style={{color: lastChanges > 0 ? 'green' : (lastChanges < 0 ? 'red' : 'inherit')}}>
                                                    {lastChanges !== 0 && (lastChanges > 0 ? '+' : '-')}
                                                    {Math.abs(lastChanges)}
                                                </TableCell>
                                                <TableCell>{getActivityTime(user.userActivities)}</TableCell>
                                                <TableCell><a
                                                    href={centerHref}>{user.center?.name ?? ""}</a></TableCell>
                                                <TableCell><a
                                                    href={centerHref}>{user.center?.city ?? ""}</a></TableCell>
                                                <TableCell><a
                                                    href={centerHref}>{user.center?.country ?? ""}</a></TableCell>
                                                <TableCell><a
                                                    href={activityProgressHref}>{user.lastName}</a></TableCell>
                                                <TableCell><a
                                                    href={activityProgressHref}>{user.firstName}</a></TableCell>
                                                <TableCell><a
                                                    href={tabletUsersHref}>{user.tabletIdentifier}</a></TableCell>
                                                <TableCell><a href={tabletUsersHref}>{user.tabletName}</a></TableCell>
                                                <TableCell onClick={() => toggleRowExpansion(idx)}
                                                           style={{cursor: 'pointer'}}>
                                                    {expandedRows.includes(idx) ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                                </TableCell>
                                            </TableRow>
                                            {expandedRows.includes(idx) && (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={5}> {/* colspan should be adjusted according to the number of columns */}
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell className="bold-header">تغییر از آخرین
                                                                        گزارش</TableCell>
                                                                    <TableCell className="bold-header">نام
                                                                        لیست</TableCell>
                                                                    <TableCell className="bold-header">مجموع
                                                                        یکانه‌ها</TableCell>
                                                                    <TableCell className="bold-header">تکمیل
                                                                        شده</TableCell>
                                                                    <TableCell className="bold-header">نسخه</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {user.userActivities
                                                                    .sort((a, b) => a.uniqueName.localeCompare(b.uniqueName))
                                                                    .map((activity, index) => {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell
                                                                                    style={{color: activity.lastChange > 0 ? 'green' : (activity.lastChange < 0 ? 'red' : 'inherit')}}>
                                                                                    {activity.lastChange !== 0 && (activity.lastChange > 0 ? '+' : '-')}
                                                                                    {Math.abs(activity.lastChange)}
                                                                                </TableCell>
                                                                                <TableCell>{activity.listName}</TableCell>
                                                                                <TableCell>{activity.total}</TableCell>
                                                                                <TableCell>{activity.completed}</TableCell>
                                                                                <TableCell>{activity.version !== null ? activity.version : ""}</TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    })}
                                                                <TableRow>
                                                                    <TableCell colSpan={10}>
                                                                        <TextareaAutosize
                                                                            id={`desc-${idx}`}
                                                                            rows={4} // Set the default number of rows
                                                                            rowsMax={4} // Set the maximum number of rows before the scrollbar appears
                                                                            placeholder="توضیحات در رابطه با کاربر وارد کنید..."
                                                                            dir="rtl"
                                                                            style={{
                                                                                border: '1px solid #ccc',
                                                                                outline: '2px solid #ccc',
                                                                                outlineOffset: '2px',
                                                                                borderRadius: '4px',
                                                                                padding: '0.5rem',
                                                                                width: '100%',
                                                                                resize: 'vertical', overflow: 'auto'
                                                                            }}
                                                                            value={user.tabletUserDescription}
                                                                        />
                                                                        <Button variant="contained" color="secondary"
                                                                                onClick={() => handleOpenDialog(user.tabletUserId, user.tabletUserDescription)}>
                                                                            اضافه کردن توضیحات
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog open={isDialogOpen} onClose={handleCloseDialog} style={{minWidth: '800px'}}>
                        <DialogTitle>متن توضیح</DialogTitle>
                        <DialogContent>
                            <TextareaAutosize
                                multiline
                                dir="rtl"
                                rows={4}
                                maxRows={5}
                                placeholder="توضیحات در رابطه با کاربر وارد کنید..."
                                style={{
                                    width: '100%',
                                    minWidth: '400px',
                                    margin: '5px',
                                    border: '1px solid #ccc',
                                    outline: '2px solid #ccc',
                                    outlineOffset: '2px',
                                    borderRadius: '4px'
                                }}
                                value={inputText}
                                onChange={(e) => setInputText(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">
                                لغو
                            </Button>
                            <Button onClick={handleSaveAndClose} color="primary">
                                اعمال
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*<PaginationComponent*/}
                    {/*    totalPages={totalPages}*/}
                    {/*    currentPage={currentPage}*/}
                    {/*    handlePageChange={handlePageChange}*/}
                    {/*/>*/}
                    {
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                            <Pagination id={'pagination'} size="lg">
                                <Pagination.First disabled={currentPage === 1} onClick={() => handlePageChange(1)}/>
                                <Pagination.Prev disabled={currentPage === 1}
                                                 onClick={() => handlePageChange(currentPage - 1)}/>
                                {pageItems}
                                <Pagination.Next disabled={currentPage === totalPages}
                                                 onClick={() => handlePageChange(currentPage + 1)}/>
                                <Pagination.Last disabled={currentPage === totalPages}
                                                 onClick={() => handlePageChange(totalPages)}/>
                            </Pagination>
                            <FormControl sx={{minWidth: 120}}>
                                <Select
                                    value={pageSize}
                                    onChange={e => {
                                        setPageSize(e.target.value);
                                        refreshProjectList(e.target.value, centerId, days);
                                    }}
                                    inputProps={{'aria-label': 'Select Page Size'}}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    }
                    <Snackbar
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        open={snackOpen}
                        onClose={handleSnackClose}
                        key={'bottom' + 'left'}
                    >
                        <Alert onClose={handleSnackClose} severity={severity} sx={{width: '100%'}}>
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                </div>
            }
        </>
    )
}
import {Sidebar, Menu, MenuItem, SubMenu, useProSidebar} from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExitToApp from "@mui/icons-material/ExitToApp";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HubIcon from '@mui/icons-material/Hub';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkIcon from '@mui/icons-material/Link';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import './Panel.css'
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import TabletIcon from '@mui/icons-material/Tablet';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { Divider } from '@mui/material';
import RoleUtil from "../../lib/RoleUtil";

export default function Panel({page}) {
    const {collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl} =
        useProSidebar();

    const toggle = () => {
        toggleSidebar();
        if (toggled) {
            collapseSidebar();
        } else {
            collapseSidebar();
        }
    };

    function getBarName() {
        let firstName = sessionStorage.getItem("firstName");
        if (!firstName) return "";
        return firstName + ' ' + sessionStorage.getItem("lastName") + ' | ';
    }

    return (
        <div id="app" style={({height: "100vh"}, {display: "flex", flexDirection: "row"})}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>داناک | پنل مدیریت</title>
            </Helmet>
            <Box id={'panelbox'}>
                <AppBar sx={{background: '#DCEDC8'}} position="static">
                    <Toolbar>
                        <Box sx={{flexGrow: 0}}>
                            <Tooltip title="Open settings">
                                <IconButton sx={{p: 0}}>
                                    <Avatar alt="Remy Sharp" src={require('../../assets/icons/profile/profile.jpg')}/>
                                </IconButton>
                            </Tooltip>
                            <Menu sx={{mt: '45px'}} id="menu-appbar"
                                  anchorOrigin={{vertical: 'top', horizontal: 'right',}} keepMounted
                                  transformOrigin={{vertical: 'top', horizontal: 'right',}}>
                            </Menu>
                        </Box>
                        <Typography id={'apptitle'} variant="h6" component="div" sx={{flexGrow: 1}}>
                            {getBarName()} پنل مدیریت کاربری
                        </Typography>

                    </Toolbar>
                </AppBar>
            </Box>
            <Sidebar id="sidebar" breakPoint="sm" transitionDuration={800} rtl={true}>
                {!broken && (
                    <Menu>
                        <MenuItem icon={<MenuOutlinedIcon/>} onClick={() => {
                            collapseSidebar();
                        }} style={{textAlign: "center"}}>
                            {" "}
                        </MenuItem>

                        <MenuItem icon={<HomeOutlinedIcon/>} component={<Link to="/dashboard"/>}>داشبورد</MenuItem>
                        <MenuItem icon={<AssessmentIcon/>}  component={<Link to="/desktop"/>} >فعالیت های کودکان
                        </MenuItem>
                        <MenuItem icon={<RememberMeIcon/>} component={<Link to="/tablet-users"/>}>کاربران تبلت</MenuItem>
                        <MenuItem icon={<TabletIcon/>} component={<Link to="/tablets"/>}>تبلت ها</MenuItem>
                        {RoleUtil.isCurrentUserAdmin() && <SubMenu icon={<HubIcon/>} label="مراکز">
                            <MenuItem icon={<PersonAddAlt1Icon/>} component={<Link to="/add-donor"/>}>ثبت مدیر</MenuItem>
                            <MenuItem icon={<SupervisedUserCircleIcon/>} component={<Link to="/list-donor"/>}>لیست مدیران</MenuItem>
                            <MenuItem icon={<LinkIcon/>} component={<Link to="/center-donor"/>}>تخصیص مدیران</MenuItem>
                            <MenuItem icon={<AddLocationIcon/>} component={<Link to="/add-center"/>}>ثبت مرکز</MenuItem>
                            <MenuItem icon={<LocationOnIcon/>} component={<Link to="/list-center"/>}>لیست مراکز</MenuItem>
                        </SubMenu>}
                        <Divider style={{ height: '1px', background: 'gray' }} />
                        <MenuItem icon={<LiveHelpIcon/>} component={<Link to="/manual"/>}>راهنمای کاربری</MenuItem>
                        <MenuItem icon={<DownloadingIcon/>} component={<Link to="/download"/>}>دانلود آخرین نسخه</MenuItem>
                        <Divider style={{ height: '1px', background: 'gray' }} />
                        <MenuItem icon={<ExitToApp/>} component={<Link to="/logout"/>}>خروج از حساب</MenuItem>
                    </Menu>
                )}
            </Sidebar>
            <main id={'content'}>
                {page}
            </main>
        </div>
    );
}

import "./ActivityProgress.css"
import axios from "axios";
import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import {useLocation} from 'react-router-dom';
import PersianDateUtil from "../../lib/PersianDateUtil";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {Line, Pie} from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default function ActivityProgress(props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const firstName = queryParams.get('name');
    const lastName = queryParams.get('lname');
    const androidId = queryParams.get('aid');
    const identifier = queryParams.get('ide');
    const tabletId = queryParams.get('tid');

    const [tabletUserList, setTabletUserList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [firstPage, setFirstPage] = useState(true);
    const [lastPage, setLastPage] = useState(false);
    const [barChartData, setBarChartData] = useState([]);
    const [pieChartData, setPieChartData] = useState([]);
    const [editingIndex, setEditingIndex] = useState(-1); // Track the index of the row being edited
    const [editedValue, setEditedValue] = useState(""); // Track the edited value
    const [state, setState] = useState({
        open: false,
        alertMessage: 'مشکلی در ثبت پیش آمد!',
        severity: 'error'
    });
    const {open, alertMessage, severity} = state;
    const handleClose = () => {
        setState({...state, open: false});
    };
    useEffect(() => {
        getTabletUsers(userId);
    }, [currentPage]); // Fetch the data when the component mounts

    ///////////////
    function handlePageChange(pageNumber) {
        setCurrentPage(pageNumber);
    }

    const pageItems = [];

    // Add the first page item
    pageItems.push(
        <Pagination.Item key={1} active={currentPage === 1} onClick={() => handlePageChange(1)}>
            {1}
        </Pagination.Item>
    );

    // Add the previous ellipsis if necessary
    if (currentPage > 3 && totalPages > 5) {
        pageItems.push(<Pagination.Ellipsis key="prev-ellipsis"/>);
    }

    // Add the pages between the first and last pages
    for (let page = Math.max(2, currentPage - 2); page <= Math.min(totalPages - 1, currentPage + 2); page++) {
        pageItems.push(
            <Pagination.Item key={page} active={currentPage === page} onClick={() => handlePageChange(page)}>
                {page}
            </Pagination.Item>
        );
    }

    // Add the next ellipsis if necessary
    if (totalPages - currentPage > 3 && totalPages > 5) {
        pageItems.push(<Pagination.Ellipsis key="next-ellipsis"/>);
    }

    // Add the last page item
    pageItems.push(
        <Pagination.Item key={totalPages} active={currentPage === totalPages}
                         onClick={() => handlePageChange(totalPages)}>
            {totalPages}
        </Pagination.Item>
    );

    /////////////

    function processPieChart(data) {
// Create a map to store maximum completed value for each uniqueName
        const groupedData = new Map();

        data.forEach(item => {
            const {uniqueName, completed} = item;
            if (!groupedData.has(uniqueName) || completed > groupedData.get(uniqueName).completed) {
                groupedData.set(uniqueName, {...item});
            }
        });

        return Array.from(groupedData.values());
    }

    const pieOptions = {
        legend: {
            display: false
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'start',
                offset: -10,
                borderWidth: 2,
                borderColor: '#fff',
                borderRadius: 25,
                backgroundColor: (context) => {
                    return context.dataset.backgroundColor;
                },
                font: {
                    weight: 'bold',
                    size: '10'
                },
            },
        },
        layout: {
            padding: {
                bottom: 20, // Adjust this value as needed
            },
        }
    };

    const BarChartComponent = ({data}) => {
        // Create a map to store completed values for each day and uniqueName combination
        const completedByDayAndName = data.reduce((map, item) => {
            const date = item.createTimeStamp.substring(0, 10); // Extract the date part
            const key = `${date}-${item.uniqueName}`;
            const existingItem = map.get(key);
            if (!existingItem || existingItem.id < item.id) {
                map.set(key, item);
            }
            return map;
        }, new Map());

        // Group the processed data by date to calculate the summary of completed tasks for each day
        const completedSummaryByDay = [...completedByDayAndName.values()].reduce((summaryMap, item) => {
            const date = item.createTimeStamp.substring(0, 10); // Extract the date part
            if (!summaryMap.has(date)) {
                summaryMap.set(date, 0);
            }
            summaryMap.set(date, summaryMap.get(date) + item.completed);
            return summaryMap;
        }, new Map());

        // Extract timestamps and completed values from the summary map
        const timestamps = [...completedSummaryByDay.keys()];
        const completedData = timestamps.map(timestamp => completedSummaryByDay.get(timestamp));
        const filteredData = Object.values(data.reduce((acc, item) => {
            const day = item.createTimeStamp.split('T')[0];
            if (!acc[day] || acc[day].id < item.id) {
                acc[day] = item;
            }
            return acc;
        }, {}));
        // Get unique days
        const uniqueDays = [...new Set(filteredData.map(item => item.createTimeStamp.split('T')[0]))];

        // Check if there are at least seven different days
        const enoughData = uniqueDays.length >= 3;

        if (!enoughData) {
            return (
                <div>
                    <h2>ناکافی بودن داده</h2>
                    <p>برای دیدن نمودار فعالیت، به حداقل سه روز داده نیاز است.</p>
                </div>
            );
        }

        const chartData = {
            labels: timestamps.map((date) => {
                const persianDateObj = PersianDateUtil.getPersianDate(date);
                return `${persianDateObj.year}/${persianDateObj.month}/${persianDateObj.day}`;
            }),
            datasets: [{
                label: 'مجموع کل یکانه های انجام شده',
                data: completedData,
                fill: 'origin', // Do not fill below the line
                borderColor: 'rgba(75, 192, 192, 0.6)', // Specify the color of the line
                backgroundColor: 'rgba(75, 192, 192, 0.6)', // Specify the color of the fill area
                lineTension: 0.4, // Make the line curvy
            }],
        };

        const options = {
            scale: {
                ticks: {
                    precision: 0
                },
                min: 0
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    time: {
                        unit: 'day',
                    },
                    ticks: {
                        beginAtZero: true,
                        precision: 0
                    },
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Completed',
                    },
                    ticks: {
                        beginAtZero: true,
                        precision: 0
                    },
                }],
            },
            plugins: {
                datalabels: {
                    display: true,
                    align: 'center',
                    backgroundColor: '#fff',
                    borderRadius: 4,
                    color: 'rgba(75, 192, 192, 0.6)',
                    font: {
                        weight: 'bold',
                    },
                    formatter: function (value) {
                        return value || ''; // Display value or empty string if value is null or undefined
                    },
                },
            },
        };

        return (
            <div style={{width: '80%', margin: '10px'}}>
                <h2>نمودار فعالیت کودک</h2>
                <Line plugins={[ChartDataLabels]} data={chartData} options={options}/>
            </div>
        );
    };

    const PieChartComponent = ({data}) => {
        const chartData = {
            labels: ['کامل شده', 'باقی‌مانده'],
            datasets: [
                {
                    data: [data.completed, data.total - data.completed],
                    backgroundColor: ['#4cba4c', '#77beef'],
                    hoverBackgroundColor: ['#36A2EB', '#FFCE56'],
                    datalabels: {
                        color: 'white',
                        formatter: (value, context) => {
                            const total = context.chart.data.datasets[0].data.reduce((acc, cur) => acc + cur, 0);
                            return value + ' (' + Math.round((value / total) * 100) + '%)';
                        }
                    }
                },
            ],
        };

        return (
            <div style={{width: '200px', margin: '10px', marginBottom: '20px'}}>
                <h2>{data.listName}</h2>
                <Pie plugins={[ChartDataLabels]} data={chartData} options={pieOptions}/>
            </div>
        );
    };

    function getTabletUsers(activityId) {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/activities?page=${currentPage - 1}&size=${pageSize}&sort=id,desc&${activityId ? `&activityId.equals=${activityId}` : ''}`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        })
            .then((res) => {
                setTotalPages(res.data.totalPages);
                setTabletUserList(res.data.content);
                setFirstPage(res.data.first);
                setLastPage(res.data.last);
            })
            .catch((err) => console.log(err));
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/activities?page=${0}&size=${999999}&sort=id,asc&${activityId ? `&activityId.equals=${activityId}` : ''}`,
            headers: {Authorization: `Bearer ${sessionStorage.getItem('TOKEN')}`},
        })
            .then((res) => {
                setBarChartData(res.data.content);
                setPieChartData(processPieChart(res.data.content))
            })
            .catch((err) => console.log(err));
    }

    let swapColor = true;

    function isCloseTime(currentTime, previousTime) {
        let timeDifference = (currentTime - previousTime) / 1000; // difference in seconds
        console.log('currentTime', currentTime, 'previousTime', previousTime,'diff',timeDifference,
            'result',timeDifference > 30 || timeDifference < -30,'swapColor',swapColor);
        return timeDifference > 30 || timeDifference < -30;
    }

    return <>
        {firstName && lastName && <div className="material-box material-box-green">
            <h1>فعالیت های کاربر</h1>
        </div>}
        {androidId && firstName && lastName && <div className="material-box">
            <span>نام کاربری: <a href={`/activity-progress?userId=${userId}`}>{firstName} {lastName}</a></span>
            <br/>
            <span>تبلت: <a
                href={`/tablet-users?tabletId=${tabletId}&aid=${androidId}&ide=${identifier ?? ''}`}>{androidId}</a></span>
            <br/>
            {
                identifier !== ''
                    ? <span>شناسه داخلی تبلت: <a href={`/tablet-users?tabletId=${tabletId}`}>{identifier}</a></span>
                    : <span>شناسه داخلی تبلت: تخصیص داده نشده!</span>
            }
        </div>}
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            {pieChartData.map((item, index) => <PieChartComponent key={index} data={item}/>)}
        </div>
        <div>
            <BarChartComponent data={barChartData.filter((item) => item.createTimeStamp)}/>
        </div>
        <Table id="tablecontent" className="material-table" bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>اسم لیست</th>
                <th>اسم یکتای لیست</th>
                <th>مجموع یونیت ها</th>
                <th>تکمیل کرده</th>
                <th>تاریخ ایجاد</th>
                <th>زمان ایجاد</th>
            </tr>
            </thead>
            <tbody>
            {
                tabletUserList.map((row, idx) => {
                    let currentTime = new Date(row.createTimeStamp), previousTime;
                    if (idx === 0 || tabletUserList.length === 1) previousTime = currentTime;
                    else previousTime = new Date(tabletUserList[idx - 1].createTimeStamp);
                    let closeTime = isCloseTime(currentTime, previousTime);
                    if (closeTime) swapColor = !swapColor;
                    let className = swapColor ? 'similar-time' : 'not-similar-time';
                    let persianDate = PersianDateUtil.getPersianDate(row.createTimeStamp);
                    return (
                        <tr key={idx} className={className}>
                            <td>{row.id}</td>
                            <td>{row.listName}</td>
                            <td>{row.uniqueName}</td>
                            <td>{row.total}</td>
                            <td>
                                {editingIndex === idx ? (
                                    <input
                                        type="number"
                                        value={editedValue}
                                        onChange={(e) => setEditedValue(e.target.value)}
                                    />
                                ) : (
                                    row.completed
                                )}
                            </td>
                            <td>{`${persianDate.year}/${persianDate.month}/${persianDate.day}`}</td>
                            <td>{`${persianDate.hours}:${persianDate.minutes}:${persianDate.seconds}`}</td>
                        </tr>
                    );
                })
            }
            </tbody>
        </Table>
        {
            !(firstPage === true && lastPage === true) && <Pagination id={'pagination'} size="lg">
                <Pagination.First disabled={currentPage === 1} onClick={() => handlePageChange(1)}/>
                <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}/>
                {pageItems}
                <Pagination.Next disabled={currentPage === totalPages}
                                 onClick={() => handlePageChange(currentPage + 1)}/>
                <Pagination.Last disabled={currentPage === totalPages}
                                 onClick={() => handlePageChange(totalPages)}/>
            </Pagination>
        }
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            open={open}
            onClose={handleClose}
            key={'bottom' + 'left'}
        >
            <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
                {alertMessage}
            </Alert>
        </Snackbar>
    </>
        ;


}
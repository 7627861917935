class AuthenticationService {
    storeToken(token) {
        sessionStorage.setItem('TOKEN', token)
    }

    isUserLoggedIn() {
        let user = sessionStorage.getItem('TOKEN');
        return user != null;
    }

    storeProfile(profile) {
        sessionStorage.setItem('email',profile.email);
        sessionStorage.setItem('firstName',profile.firstName);
        sessionStorage.setItem('lastName',profile.lastName);
        sessionStorage.setItem('imageUrl',profile.imageUrl);
        sessionStorage.setItem('login',profile.login);
        sessionStorage.setItem('authorities',profile.authorities);
    }
}

export default new AuthenticationService()